let cources;

let currencySymbols = {
    'PLN' : 'zł',
    'RUB' : '₽',
    'EUR' : '€',
    'USD' : '$',
    'UAH' : '₴'
}

document.addEventListener("DOMContentLoaded", function(){
    $.ajax({
        url : "/get_cources",
        type: "GET",
        success: function(response)
        {
            cources = JSON.parse(response);
        },
    });
});

///////////Validation name
document.querySelector('.ds-text-input').addEventListener('input', function(e){
    let t = e.target.value.replace(/\_*[^A-zА-яіїІЇ\w\-]/gi, '');
    e.target.value = t;
});

/////////// Get form data
function getFormData(){
    let siteName = document.getElementById('site_name').value.toLowerCase();
    let domainsGet = document.querySelectorAll(".ds-main-form input:checked");
    let domains = [];

    for(let i=0; i < domainsGet.length; i++){
        let k = domainsGet[i].value;
        domains.push(k);
    }
    
    if(siteName.replace(/\_*[^A-zА-яіїІЇ\w\-]/gi, '').length >= 5){
        if(domains.length != 0){

            search(siteName, domains);

            let valuta = document.querySelector('.ds-valuta-grid');
            let result = document.querySelector('.ds-result-form');
            let accept = document.querySelector('.ds-form-accept-data');
            let registr = document.querySelector('.ds-registr-domain');

            valuta.style.opacity = '0';
            valuta.style.height = '0';

            result.style.opacity = '0';
            result.style.height = '0';

            accept.style.opacity = '0';
            accept.style.height = '0';

            registr.style.opacity = '0';
            registr.style.height = '0';

            const close = () => { 
                valuta.style = 'display: none;';
                result.style = 'display: none;';
                accept.style = 'display: none;';
                registr.style = 'display: none;';
            }
            setTimeout(close, 300);



            document.querySelector('.ds-loading').style.opacity = '1';
        }
        else{
            alert('Вы не выбрали ни одного домена')
        }
    }
    else {
        alert('Название сайта пустое или слишком короткое!')
    }

}


/////////// Start search

function search(name, domains){

    let answerBlock = document.querySelector('.ds-result-form');
    answerBlock.innerHTML = '';

    console.log(name);

    $.ajax({
        url : "/check_domain",
        type: "POST",
        data : {
            'domain_type' : domains ,
            'name': name

        },
        success: function(response)
        {
            console.log(response);
            document.querySelector('.ds-loading').style.opacity = '';

            let valuta = document.querySelector('.ds-valuta-grid');
            let result = document.querySelector('.ds-result-form');
            let accept = document.querySelector('.ds-form-accept-data');
            let registr = document.querySelector('.ds-registr-domain');
            let header = document.querySelector('header');
            let title = document.querySelector('.ds-h1-container');

            valuta.style = 'display: grid;';
            result.style = 'display: grid;';
            accept.style = 'display: block;';
            registr.style = 'display: block;';

            header.style.gridTemplateRows = '45px';
            
            title.style.opacity = '0';
            title.style.height = '0';

            const open = () => {

                valuta.style.opacity = '';
                valuta.style.height = '';

                result.style.opacity = '';
                result.style.height = '';

                accept.style.opacity = '';
                accept.style.height = '';

                registr.style.opacity = '';
                registr.style.height = '';
            }
            setTimeout(open, 0);

            const domen = JSON.parse(response);

            let selectedValuta = $('.ds-valuta-selected').attr('data-valuta');

            for (const [key, value] of Object.entries(domen)){

                if(value.status=="busy"){

                    answerBlock.innerHTML += '<div class="ds-result-wrap"><div class="ds-col-check"><div class="ds-check-wrap"><input type="checkbox"><label class="busy">'+ key +'</label></div><div></div></div><div class="ds-col-price"><div class="ds-old-price busy"></div><div class="ds-current-price busy">BUSY</div><div class="ds-avalible"></div></div></div>';
                    
                }
                else{
                    
                    answerBlock.innerHTML += '<div class="ds-result-wrap"><div class="ds-col-check"><div class="ds-check-wrap"><input type="checkbox" value="'+ key +'" id="'+ key +'"><label for="'+ key +'">'+ key +'</label></div><div></div></div><div class="ds-col-price"><div class="ds-old-price" data-uah="'+ (value.price*1.2).toFixed(2)+'">'+ (value.price*1.2).toFixed(2)+'</div><div class="ds-current-price" data-uah="'+ value.price +'">'+currencySymbols[selectedValuta] + ' ' + value.price +'<span>'+selectedValuta+'</span></div><div class="ds-avalible">available</div></div></div>';
            
                }

            }
        },
    });
}

/////////// Select valuta

$('.ds-valuta').on('click', function(){


    $('.ds-valuta').removeClass('ds-valuta-selected');
    $(this).addClass('ds-valuta-selected');

    let valuta = $(this).attr('data-valuta');
    let course;

    for (const [key, value] of Object.entries(cources)) {
        if(key == valuta){
            course = value;
        }
    }

    let oldPrices = document.querySelectorAll('.ds-old-price:not(.busy)');
    let prices = document.querySelectorAll('.ds-current-price:not(.busy)');

    for(let i = 0; i < oldPrices.length; i++){

        if(valuta != 'UAH'){
            oldPrices[i].innerHTML = (oldPrices[i].getAttribute('data-uah') / course).toFixed(1);
            prices[i].innerHTML = currencySymbols[valuta] + ' ' + (prices[i].getAttribute('data-uah') / course).toFixed(1) + '<span>'+valuta+'</span>';
        }
        else{
            oldPrices[i].innerHTML = oldPrices[i].getAttribute('data-uah');
            prices[i].innerHTML = currencySymbols['UAH'] + ' ' + prices[i].getAttribute('data-uah') + '<span>'+valuta+'</span>';
        }

        console.log(oldPrices[i].getAttribute('data-uah'));
        console.log(course);
        console.log((oldPrices[i].getAttribute('data-uah') / course).toFixed(1));
        console.log((prices[i].getAttribute('data-uah') / course).toFixed(1));
    }
});;

/////////// Registration domains

function buyDomain(){
    let logged = document.querySelector('#hdnSession');

    if (logged == null){

        openLoginForm();

    }
    else {
        
        let domainsForOrder = [];
        let accept = document.querySelector('#ds_form_accept_data');
        let check = false;
        let domainsGet = document.querySelectorAll('.ds-result-form input:checked');

        let cname = document.querySelector('#hdnSession').getAttribute('data-name');
        let cemail = document.querySelector('#hdnSession').getAttribute('data-email');

        let logged = document.querySelector('#hdnSession');
        console.log(logged);
        

        for(let i=0; i < domainsGet.length; i++){

            let k = domainsGet[i].value;
            domainsForOrder.push(k);

        }

        (accept.checked && domainsForOrder.length != 0 && cname.length != 0 && cemail.length != 0) ? check = true : alert("Вы не отметили никаких имен и не \nдали разрешения на обработку данных");

        
        if(check){

            $.ajax({
                url : "/buy_domain",
                type: "POST",
                data : ({
                    'domain_type' : domainsForOrder, 
                    'customer_name' : cname,
                    'customer_email' : cemail
                }),
                success: function(response)
                {
                    let answerBlock = document.querySelector('.ds-result-form');
                    answerBlock.innerHTML = '';

                    let valuta = document.querySelector('.ds-valuta-grid');
                    let result = document.querySelector('.ds-result-form');
                    let accept = document.querySelector('.ds-form-accept-data');
                    let registr = document.querySelector('.ds-registr-domain');
                    let header = document.querySelector('header');
                    let title = document.querySelector('.ds-h1-container');

                    valuta.style.display = '';
                    result.style.display = '';
                    accept.style.display = '';
                    registr.style.display = '';

                    header.style.gridTemplateRows = '';
                },
            });
        }

    }
}